import Overlay from '@/src/components/Overlay'
import { Heading } from '@/src/components/Typography'
import Paragraph from '@/src/components/Typography/Paragraph'
import { usePreventScroll } from '@/src/hooks/usePreventScroll'
import { closeModal, selectTargetModals } from '@/src/store/slices/modalSlice'
import { useAppDispatch } from '@/src/store/store'
import { isMobile } from '@/src/utils/helpers/browserHelpers'
import classNames from 'classnames'
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type JSX,
} from 'react'
import ReactDOM from 'react-dom'
import { MdOutlineClose } from 'react-icons/md'
import { useSelector } from 'react-redux'

type ModalProps = {
  targetModal: string
  children: JSX.Element
  allowClose?: boolean
  allowClickOutside?: boolean
  showCloseButton?: boolean
  className?: string
  onClose?: () => void
  wrapperId?: string
  scrollable?: boolean
  bottom?: boolean
  heading?: string | JSX.Element
  subHeading?: string | JSX.Element
  textSection?: string | JSX.Element
}

const NewModal = ({
  targetModal,
  children,
  allowClose = true,
  allowClickOutside = true,
  showCloseButton = true,
  className = '',
  onClose,
  wrapperId = 'app-main',
  scrollable = false,
  bottom = false,
  heading,
  subHeading,
  textSection,
}: ModalProps) => {
  const dispatch = useAppDispatch()
  const [wrapper, setWrapper] = useState<Element | null>(null)
  const selector = useSelector(selectTargetModals)
  const { disableScroll, enableScroll } = usePreventScroll()

  const atBottom = bottom || isMobile()

  const selectedModal = useMemo(
    () =>
      selector.targetModals.find((modal) => modal.modalName === targetModal),
    [selector.targetModals, targetModal],
  )

  const closeEvent = useCallback(() => {
    if (onClose) {
      onClose()
    }
    dispatch(closeModal(targetModal))
    enableScroll()
  }, [dispatch, enableScroll, onClose, targetModal])

  useEffect(() => {
    selectedModal?.visible && !scrollable ? disableScroll() : enableScroll()
  }, [disableScroll, enableScroll, selectedModal?.visible, scrollable])

  useEffect(() => {
    setWrapper(document.getElementById(wrapperId))

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && selectedModal?.visible && allowClose) {
        closeEvent()
      }
    }

    window.addEventListener('keydown', handleEscKey)
    return () => {
      window.removeEventListener('keydown', handleEscKey)
    }
  }, [wrapperId, selectedModal, closeEvent, allowClose])

  if (wrapper === null) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      id={targetModal}
      aria-hidden='true'
      role='dialog'
      className={classNames(
        `h-modal fixed bottom-0 left-0 right-0 top-0 z-50 justify-center overflow-x-hidden overflow-y-hidden md:inset-0 md:h-full`,
        {
          hidden: !selectedModal?.visible,
          flex: selectedModal?.visible,
          'items-end': atBottom,
          'items-center': !atBottom,
        },
      )}
    >
      {selectedModal?.visible && (
        <>
          <Overlay onClick={allowClickOutside ? closeEvent : undefined} />
          <div
            className={classNames(
              'relative z-100 w-full max-w-lg overflow-hidden rounded-t-xl bg-primary px-14 py-10 text-center shadow-shinny md:h-auto xs:rounded-2xl bigPhone:px-0',
              {
                'animate-in slide-in-from-bottom': atBottom,
                'animate-in zoom-in-50': !atBottom,
              },
              className,
            )}
          >
            {allowClose && showCloseButton && (
              <button
                aria-label='close'
                type='button'
                className='absolute right-0 top-0 z-40 m-4 ml-auto inline-flex items-center rounded-full bg-white/10 p-1.5 text-sm'
                onClick={closeEvent}
              >
                <MdOutlineClose size='28px' />
              </button>
            )}

            {(heading || subHeading || textSection) && (
              <div className='mx-auto max-w-[19.5rem] sm:max-w-[24rem]'>
                {(heading || subHeading) && (
                  <div className='mb-4'>
                    {heading && (
                      <Heading level={2} className='uppercase'>
                        {heading}
                      </Heading>
                    )}
                    {subHeading && <Heading level={4}>{subHeading}</Heading>}
                  </div>
                )}
                {textSection && <Paragraph>{textSection}</Paragraph>}
              </div>
            )}

            <div className='flex flex-col items-center justify-center'>
              {children}
            </div>
          </div>
        </>
      )}
    </div>,
    wrapper,
  )
}

export default memo(NewModal)
