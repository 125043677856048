import { useCallback, useEffect } from 'react'

export function usePreventScroll() {
  const preventScroll = (e: TouchEvent | WheelEvent) => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const disableScroll = useCallback(() => {
    document.addEventListener('touchmove', preventScroll, { passive: false })
    document.addEventListener('wheel', preventScroll, { passive: false })
  }, [])

  const enableScroll = useCallback(() => {
    document.removeEventListener('touchmove', preventScroll)
    document.removeEventListener('wheel', preventScroll)
  }, [])

  useEffect(() => {
    return () => {
      enableScroll()
    }
  }, [enableScroll])

  return { disableScroll, enableScroll }
}
