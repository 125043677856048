'use client'
import { useLanguage } from '@/src/components/LanguageProvider'
import { appendLangToUrl } from '@/src/components/LocalizedLink/utls'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'

// Extract Link props using React.ComponentProps<typeof Link>
type LocalizedLinkProps = React.ComponentProps<typeof Link> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

const LocalizedLink: React.FC<PropsWithChildren<LocalizedLinkProps>> = ({
  href,
  children,
  ...props
}) => {
  const { lang } = useLanguage()

  // Construct the localized href
  const localizedHref = appendLangToUrl(href, lang)

  return (
    <Link href={localizedHref} {...props}>
      {children}
    </Link>
  )
}

export default LocalizedLink
