import { ContactsFormInput, PlayerType } from '@/src/services/types'
import { DuplicationFuncType } from '@/src/services/types/player'
import MD5 from 'crypto-js/md5'

export const checkEmailDuplication = async (
  info: ContactsFormInput,
  userData: PlayerType | Record<string, never>,
  checkDuplication: DuplicationFuncType,
) => {
  if (info.email && info.email !== userData.email) {
    const emailDuplicate = await checkDuplication({
      name: 'email',
      value: info.email || '',
    }).unwrap()

    if (emailDuplicate.success) {
      return { email: info.email, nickName: MD5(info.email).toString() }
    } else {
      return false
    }
  }
  return {}
}

export const checkPhoneDuplication = async (
  phoneNumberPrefix: string,
  info: ContactsFormInput,
  userData: PlayerType | Record<string, never>,
  checkDuplication: DuplicationFuncType,
) => {
  const infoPhone = `${phoneNumberPrefix}${info.phoneNumber}`

  if (info.phoneNumber && infoPhone !== userData.phoneNumber) {
    const phoneDuplicate = await checkDuplication({
      name: 'phoneNumber',
      value: infoPhone || '',
    }).unwrap()

    if (phoneDuplicate.success) {
      return { phoneNumber: info.phoneNumber }
    } else {
      return false
    }
  }
  return {}
}
