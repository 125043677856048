import LobbyPromoImage from '@/src/components/ImageSrcSet/LobbyPromoImage'

const css: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  width: '100%',
  height: '100%',
  maxHeight: '400px',
  objectFit: 'cover',
  objectPosition: 'center',
}

const StarsBackground = () => {
  return (
    <>
      <LobbyPromoImage
        classNames='w-full max-w-none object-cover object-center opacity-80 normalPhone:max-h-96 normalPhone:object-[40%] select-none'
        css={css}
      />
      <div className='absolute left-0 top-0 min-h-[500px] min-w-full bg-gradient-to-b from-transparent from-30% to-primary to-100%' />
    </>
  )
}

export default StarsBackground
