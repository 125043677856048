import { ContactsFormErrorHandling } from '@/src/components/Steps/utils/ErrorHandling/ContactsForm'
import { VerifyFormErrorHandling } from '@/src/components/Steps/utils/ErrorHandling/VerifyForm'
import LimitsInfo from '@/src/components/Steps/utils/LimitsInfo'
import {
  checkEmailDuplication,
  checkPhoneDuplication,
} from '@/src/components/Steps/utils/duplicationOutput.tsx'
import {
  buildSteps,
  getSteps,
  regHelper,
} from '@/src/components/Steps/utils/regHelper.tsx'

export {
  buildSteps,
  checkEmailDuplication,
  checkPhoneDuplication,
  ContactsFormErrorHandling,
  getSteps,
  LimitsInfo,
  regHelper,
  VerifyFormErrorHandling,
}
