import Loading from '@/src/components/Loading'
import React from 'react'

type LoadingModalProps = {
  children?: React.ReactNode
}

const LoadingModal = ({ children }: LoadingModalProps) => {
  return (
    <div className='flex h-40 flex-col items-center justify-center rounded-3xl border-none'>
      <div className='flex items-center justify-center'>
        <Loading loading={true} inline />
      </div>
      {children && <div className='text-sm'>{children}</div>}
    </div>
  )
}

export default LoadingModal
