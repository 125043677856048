import cn from 'classnames'

type OverlayType = {
  onClick?: () => void
  freezeScroll?: boolean
  search?: boolean
}

const Overlay = ({ onClick, search = false }: OverlayType) => {
  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 right-0 top-0 z-50 h-screen w-screen',
        search
          ? 'animate-searchOverlay bg-primary opacity-100'
          : 'animate-menuOverlay bg-primary-shade opacity-80',
      )}
      onClick={onClick}
    />
  )
}

export default Overlay
