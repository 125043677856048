import {
  AllOnboardingSteps,
  MissingInfoSteps,
} from '@/src/app/[lang]/missing-info/[uid]/_types/missing-info-types'
import { LimitsFormattedType, PlayerType } from '@/src/services/types'
import {
  MandatoryUserInfoType,
  SiteConfigType,
} from '@/src/services/types/config'
import { store } from '@/src/store/store.tsx'

export type RegHelperType = {
  hasPhoneOrEmailVerified: boolean
  hasDepositLimits: boolean
  hasGeneralInfo: boolean
}

const buildSteps = (
  userValidation: RegHelperType | Record<string, never>,
  mandatoryUserInfo?: MandatoryUserInfoType,
) => {
  const { getState } = store
  const missingMandatoryData = {
    //always go to emailphone if its missing since its mandatory for every branch and market :P
    phoneOrEmailVerified: !userValidation.hasPhoneOrEmailVerified,
    depositLimits:
      !userValidation.hasDepositLimits && mandatoryUserInfo?.depositLimits,
  }

  const userSteps: MissingInfoSteps = []

  AllOnboardingSteps.forEach((_step) => {
    if (userSteps.indexOf(`/missing-info/${_step}`) > -1) return

    switch (_step) {
      case 'phone-email':
        if (missingMandatoryData.phoneOrEmailVerified) {
          userSteps.push(`/missing-info/${_step}`)
        }
        break
      case 'deposit-limits':
        if (missingMandatoryData.depositLimits) {
          userSteps.push(`/missing-info/${_step}`)
        }
        break
      case 'complete-payment':
        if (getState().navigation.showCompletePayment) {
          userSteps.push(`/missing-info/${_step}`)
        }
        break
      case 'complete':
        if (getState().navigation.showComplete) {
          userSteps.push(`/missing-info/${_step}`)
        }
        break
    }
  })

  if (userSteps.length === 0) {
    userSteps.push('/')
  }

  return userSteps
}

const regHelper = (
  userDetails: PlayerType,
  limits: LimitsFormattedType,
): RegHelperType => {
  const hasPhoneOrEmailVerified =
    !!userDetails.email &&
    (!!userDetails.emailVerified ||
      (!!userDetails.phoneNumber && !!userDetails.phoneNumberVerified))

  const hasDepositLimits = !!(
    limits?.dailyDeposit?.dailyDeposit &&
    limits?.weeklyDeposit?.weeklyDeposit &&
    limits?.monthlyDeposit?.monthlyDeposit
  )

  const hasGeneralInfo =
    !userDetails.country &&
    !userDetails.citizenship &&
    !userDetails.annualFinActivityTo

  return {
    hasDepositLimits,
    hasPhoneOrEmailVerified,
    hasGeneralInfo,
  }
}

const getSteps = (
  userDetails: PlayerType,
  limits: LimitsFormattedType,
  site_config: SiteConfigType,
): MissingInfoSteps => {
  if (userDetails?.error || limits?.error) return ['/']
  const userValidation = regHelper(userDetails, limits)
  return buildSteps(userValidation, site_config.mandatoryUserInfo)
}

export { buildSteps, getSteps, regHelper }
