'use client'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import {
  useGetIntercomTokenQuery,
  useGetPlayerQuery,
} from '@/src/services/player.tsx'
import {
  selectAllowShowIntercom,
  setAllowShowIntercom,
} from '@/src/store/slices/uiSlice'
import {
  checkIntercomLoaded,
  rebootIntercom,
  showIntercomWidget,
} from '@/src/utils/intercome'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

type SupportLinkProps = {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  classWrapper?: string
  skipWrapperStyle?: boolean
  isFooter?: boolean
}

export default function IntercomWidget({
  children,
  onClick,
  classWrapper = '',
  className = '',
  skipWrapperStyle,
  isFooter = false,
}: SupportLinkProps) {
  const allowShowIntercom = useSelector(selectAllowShowIntercom)
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuthGuard()
  const { data: user } = useGetPlayerQuery(undefined, {
    skip: !isLoggedIn,
  })
  const { data: token } = useGetIntercomTokenQuery(undefined, {
    skip: !isLoggedIn,
  })

  const openSupport = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (!allowShowIntercom) dispatch(setAllowShowIntercom(true))

    await checkIntercomLoaded()
    rebootIntercom(user, token)
    showIntercomWidget()

    if (onClick) onClick()
  }

  const link = (
    <a
      href='#'
      className={isFooter ? classWrapper : className}
      onClick={(e: React.MouseEvent) => openSupport(e)}
    >
      {children}
    </a>
  )

  return (
    <>
      {isFooter ? (
        link
      ) : (
        <li
          className={cn(
            !skipWrapperStyle
              ? 'border-b-solid flex h-[50px] items-center border-b-[1px] border-[#73338e]'
              : '',
            classWrapper,
          )}
        >
          {link}
        </li>
      )}
    </>
  )
}
