'use client'
import LoaderInline from '@/src/components/Loading/LoaderInline'
import LuckyLoader from '@/src/components/Loading/LuckyLoader'
import { Heading } from '@/src/components/Typography'
import cn from 'classnames'

export type LoaderType = 'lucky' | 'inline'

type LoaderProps = {
  theme?: 'dark' | 'light'
  type?: LoaderType
  backgroundClass?: string
  transparent?: boolean
  header?: string | null
}

const Loader = ({
  theme = 'dark',
  type = 'lucky',
  backgroundClass = 'bg-primary',
  transparent = false,
  header = null,
}: LoaderProps) => {
  return (
    <div
      className={cn(
        type !== 'inline' &&
          `flex h-full w-full items-center justify-center p-5 ${
            transparent ? '' : backgroundClass
          }`,
      )}
    >
      <div>
        {header && <Heading level={4}>{header}</Heading>}
        {type === 'lucky' && <LuckyLoader theme={theme} />}
        {type === 'inline' && <LoaderInline />}
      </div>
    </div>
  )
}

export default Loader
