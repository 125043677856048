import StarsBackground from '@/src/components/Layout/StarsBackground'
import Loader, { LoaderType } from '@/src/components/Loading/Loader.tsx'
import styles from '@/src/components/Loading/loading.module.scss'
import { Heading } from '@/src/components/Typography'
import cn from 'classnames'
import type { JSX } from 'react'

type LoadingType = {
  children?: JSX.Element[] | JSX.Element
  loading: boolean
  theme?: 'dark' | 'light'
  type?: LoaderType
  inline?: boolean
  title?: string
  className?: string
  position?: 'fixed' | 'absolute' | 'relative'
  backgroudType?: 'stars' | 'primary'
  transparent?: boolean
  eKycLoading?: boolean
  eKycText?: string
}

const Loading = ({
  children,
  loading,
  theme = 'dark',
  title,
  inline,
  className,
  position = 'absolute',
  type = 'lucky',
  backgroudType,
  transparent = false,
  eKycLoading = false,
  eKycText,
}: LoadingType) => {
  return loading ? (
    <div
      className={cn(
        position,
        'bottom-0 left-0 right-0 top-0 z-10 flex flex-col items-center justify-center',
        { [styles.inlineWrapper]: inline },
        styles.wrapper,
        styles[theme],
        className,
      )}
    >
      {backgroudType === 'stars' && <StarsBackground />}
      {title && (
        <Heading level={2} className={styles.title}>
          {title}
        </Heading>
      )}
      {eKycLoading && (
        <Heading level={2} className={styles.title}>
          {eKycText}
        </Heading>
      )}
      <Loader theme={theme} type={type} transparent={transparent} />
    </div>
  ) : (
    <>{children}</>
  )
}

export default Loading
