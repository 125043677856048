import styles from '@/src/components/Loading/loading.module.scss'
import cn from 'classnames'

type LuckyLoader = {
  theme?: 'dark' | 'light'
}

const LuckyLoader = ({ theme = 'dark' }: LuckyLoader) => {
  return (
    <div className={styles.container}>
      <svg
        className={cn(styles[theme], styles.circle)}
        version='1.1'
        id='L3'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'
        enableBackground='new 0 0 0 0'
      >
        <circle
          strokeWidth='0'
          cx='50'
          cy='50'
          r='50'
          style={{
            opacity: 1,
            fill: 'var(--primary-dark)',
            stroke: 'var(--primary-light)',
          }}
        />

        <circle
          style={{
            stroke: 'var(--primary-dark)',
            fill: 'var(--primary-light)',
          }}
          strokeWidth='1'
          cx='6'
          cy='46'
          r='6'
        >
          <animateTransform
            attributeName='transform'
            dur='3s'
            type='rotate'
            from='0 50 48'
            to='360 50 52'
            repeatCount='indefinite'
          />
        </circle>
      </svg>
      <svg
        className={cn(styles[theme], styles.icon)}
        width='80'
        height='50'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 429.74 800'
      >
        <animate
          attributeName='r'
          from='15'
          to='15'
          begin='0s'
          dur='1.7s'
          values='15;9;15'
          calcMode='linear'
          repeatCount='indefinite'
        />
        <animate
          attributeName='fill-opacity'
          from='1'
          to='1'
          begin='0s'
          dur='1.7s'
          values='1;.5;1'
          calcMode='linear'
          repeatCount='indefinite'
        />
        <defs>
          <linearGradient
            id='a'
            x1='23.76'
            y1='400'
            x2='405.98'
            y2='400'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0' stopColor='#ffdf07' />
            <stop offset='1' stopColor='#f8c20c' />
          </linearGradient>
        </defs>
        <path
          d='M403.96 25.97a15.99 15.99 0 0 0-13.95-8.18H135.23c-7.3 0-13.66 4.98-15.43 12.07L24.25 412.02a15.78 15.78 0 0 0 2.89 13.66c3.02 3.86 7.64 6.12 12.54 6.12h106.57L56.18 762.01c-2.37 8.47 2.58 17.26 11.05 19.62 6.66 1.86 13.76-.79 17.58-6.56l318.48-477.72c4.89-7.31 2.93-17.2-4.38-22.09-2.63-1.76-5.73-2.7-8.9-2.69h-130.5L403.48 42.15c3.08-4.91 3.26-11.1.48-16.18'
          fill='url(#a)'
        />
      </svg>
    </div>
  )
}

export default LuckyLoader
