import styles from '@/src/components/Steps/Steps.module.scss'
import { useTranslation } from 'react-i18next'

const LimitsInfo = () => {
  const { t } = useTranslation(['onboarding'])

  return (
    <div className={`mb-3 ${styles.limitsInfo}`}>{t('label.limits.info')}</div>
  )
}

export default LimitsInfo
