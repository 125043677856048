import { RichTextField } from '@prismicio/client'
import React, { Dispatch, SetStateAction } from 'react'

export const AllOnboardingSteps = [
  'phone-email',
  'deposit-limits',
  'complete',
  'complete-payment',
] as const

export type OnboardingSteps = (typeof AllOnboardingSteps)[number]

const _PrefixedOnboardingSteps = AllOnboardingSteps.map(
  (step) => `/missing-info/${step}`,
) as `/missing-info/${(typeof AllOnboardingSteps)[number]}`[]

export type MissingInfoSteps = (
  | (typeof _PrefixedOnboardingSteps)[number]
  | '/'
)[]

type PrismicDocumentsType = {
  title: RichTextField
  body: RichTextField
}

export type PrismicDocuments = {
  TermsAndConditions: PrismicDocumentsType
  ResponsibleGambling: PrismicDocumentsType
  PrivacyPolicy: PrismicDocumentsType
  PepHioTerms: PrismicDocumentsType
}

export type PnpRegFormProviderProps = {
  children: React.ReactNode
} & PrismicDocuments

export type PnpRegContextType = {
  loadingNext: boolean
  setLoadingNext: Dispatch<SetStateAction<boolean>>
  userSteps: MissingInfoSteps
  initialUserSteps: MissingInfoSteps | undefined
} & PrismicDocuments
