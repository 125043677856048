import { cms } from '@/src/services'
import {
  ReactSiteConfigType,
  SiteConfigCountryType,
  SiteConfigType,
} from '@/src/services/types/config'

const configApi = cms.injectEndpoints({
  endpoints: (builder) => ({
    getSiteConfig: builder.query<
      SiteConfigType | undefined,
      { country: SiteConfigCountryType }
    >({
      query: () => 'static/site_config.json',
      transformResponse: (
        response: ReactSiteConfigType,
        meta,
        { country },
      ): SiteConfigType => {
        const defaultConfig = response.react?.default
        const overrides = response.react && response.react[country]

        if (defaultConfig) {
          return {
            ...defaultConfig,
            ...(overrides ? overrides : {}),
          }
        }

        if (overrides) {
          return overrides
        }

        throw new Error('Missing site config')
      },
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const { useGetSiteConfigQuery } = configApi
export const { getSiteConfig } = configApi.endpoints

//SSR endpoints
export default configApi
