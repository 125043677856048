'use client'
import LoadingModal from '@/src/components/Modal/LoadingModal'
import Modal from '@/src/components/Modal/Modal'
import { useUIContext } from '@/src/contexts/ui'
import { closeModal, showModal } from '@/src/store/slices/modalSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const ShowLoadingModal = () => {
  const dispatch = useDispatch()
  const { isLoaderOpen } = useUIContext()

  useEffect(() => {
    if (isLoaderOpen) {
      dispatch(showModal('loading-modal'))
    } else {
      dispatch(closeModal('loading-modal'))
    }
  }, [dispatch, isLoaderOpen])

  return (
    <Modal
      className='h-screen w-screen'
      targetModal='loading-modal'
      allowClose={false}
      bottom={false}
    >
      <LoadingModal />
    </Modal>
  )
}

export default ShowLoadingModal
