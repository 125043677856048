import { UAParser } from 'ua-parser-js'

export type SportsBook = {
  remove: () => void
}
declare global {
  interface Window {
    opera: unknown
    opr: unknown
    safari: unknown
    chrome: unknown
    StyleMedia: unknown
    altenarWSDK: {
      init: (v: unknown) => void
      addSportsBook: (v: unknown) => SportsBook
      set: (v: unknown) => void
    }
    AltenarSportsbook: (v: unknown, g: unknown) => void
  }

  export type SportsBook = {
    remove: () => void
  }

  interface Document {
    documentMode: unknown
  }
  interface Navigator {
    userAgentData: {
      brands: {
        brand: string
        version: string
      }[]
    }
  }
}

type OperatingSystems =
  | 'iOS'
  | 'Android'
  | 'Windows'
  | 'Windows Phone'
  | 'Mac OS'
  | 'Linux'
  | 'Ubuntu'

export function getOS() {
  if (typeof window === 'undefined') return ''
  return new UAParser(navigator.userAgent).getOS().name as OperatingSystems
}

export function isMobile() {
  if (typeof window === 'undefined') return false
  return new UAParser(navigator.userAgent).getDevice().type === 'mobile'
}

export function getBrowser() {
  if (typeof window === 'undefined') return ''
  return new UAParser(navigator.userAgent).getBrowser().name
}
